import axios from "axios";

const state = {
}

const getters = {
}

const actions = {

    async queryPartialPrescriptions({rootGetters}, params={}) {
        let config = {
            headers: rootGetters['auth/authHeaders'],
            params: params
        }

        try {
            const response = await axios.get("prescriptions/partial-appointments/", config);
            return response.data
        } catch (error) {
            throw Error(error.response.data.message)
        }
    }

}

const mutations = {
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"1000px"},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('v-card',{attrs:{"light":""}},[_c('v-card-title',[_c('span',[_vm._v("Aktuelle Rezepte")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeWindow}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-container',[_c('v-row',{staticClass:"pb-0",attrs:{"align":"end"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-x":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({ref:"fromDate",attrs:{"label":"Rezeptdatum von","prepend-icon":"mdi-calendar-start","placeholder":"TT.MM.JJJJ","hide-details":"","clearable":""},on:{"input":function($event){return _vm.handleInputDateText('fromDate')},"focus":function($event){return _vm.handleFocusDateText('fromDate')}},model:{value:(_vm.selections.fromDate),callback:function ($$v) {_vm.$set(_vm.selections, "fromDate", $$v)},expression:"selections.fromDate"}},on))]}}]),model:{value:(_vm.menu.fromDate),callback:function ($$v) {_vm.$set(_vm.menu, "fromDate", $$v)},expression:"menu.fromDate"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","locale":"de-de"},on:{"input":function($event){return _vm.handleInputDatePicker('fromDate')}},model:{value:(_vm.filters.fromDate),callback:function ($$v) {_vm.$set(_vm.filters, "fromDate", $$v)},expression:"filters.fromDate"}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-x":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({ref:"toDate",attrs:{"label":"Rezeptdatum bis","prepend-icon":"mdi-calendar-end","placeholder":"TT.MM.JJJJ","hide-details":"","clearable":""},on:{"input":function($event){return _vm.handleInputDateText('toDate')},"focus":function($event){return _vm.handleFocusDateText('toDate')}},model:{value:(_vm.selections.toDate),callback:function ($$v) {_vm.$set(_vm.selections, "toDate", $$v)},expression:"selections.toDate"}},on))]}}]),model:{value:(_vm.menu.toDate),callback:function ($$v) {_vm.$set(_vm.menu, "toDate", $$v)},expression:"menu.toDate"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","locale":"de-de"},on:{"input":function($event){return _vm.handleInputDatePicker('toDate')}},model:{value:(_vm.filters.toDate),callback:function ($$v) {_vm.$set(_vm.filters, "toDate", $$v)},expression:"filters.toDate"}})],1)],1),_c('v-col',[_c('v-select',{ref:"ktType",attrs:{"items":_vm.ktTypeOptions,"label":"Kostenträger-Typ","placeholder":"Alle Kostenträger","clearable":"","hide-details":"","prepend-icon":"mdi-domain"},model:{value:(_vm.filters.ktType),callback:function ($$v) {_vm.$set(_vm.filters, "ktType", $$v)},expression:"filters.ktType"}})],1)],1),_c('v-row',{staticClass:"pt-0 mt-0",attrs:{"align":"center"}},[_c('v-col',[_c('v-text-field',{staticClass:"pb-5",attrs:{"append-icon":"mdi-magnify","label":"Suche","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"primary-nav-btn",attrs:{"color":"primary","dense":"","small":""},on:{"click":_vm.clearFilters}},[_vm._v("Zurücksetzen")])],1)],1),_c('v-data-table',{ref:"mainTable",staticClass:"elevation-1",attrs:{"loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.totalEntries,"options":_vm.dataOptions,"footer-props":{
              itemsPerPageText: 'Einträge pro Seite',
              itemsPerPageAllText: 'Alle',
            },"loading-text":"Daten werden geladen... Bitte warten","single-expand":true,"show-expand":"","expanded":_vm.expanded},on:{"update:options":function($event){_vm.dataOptions=$event},"update:expanded":function($event){_vm.expanded=$event},"pagination":_vm.paginationChanged},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" von "+_vm._s(props.itemsLength)+" ")]}},{key:"item.rezeptdatum",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.rezeptdatum))+" ")]}},{key:"item.vers_nummer",fn:function(ref){
            var item = ref.item;
return [(item.vers_nummer)?_c('span',[_vm._v(_vm._s(item.vers_nummer))]):_c('span',[_vm._v("-")])]}},{key:"item.unfalltag",fn:function(ref){
            var item = ref.item;
return [(item.unfalltag)?_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.unfalltag)))]):_c('span',[_vm._v("-")])]}},{key:"item.kostentraeger_ik_num",fn:function(ref){
            var item = ref.item;
return [(item.kostentraeger_ik_num)?_c('span',[_vm._v(_vm._s(item.kostentraeger_ik_num))]):_c('span',[_vm._v("-")])]}},{key:"item.actions",fn:function(ref){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dense":"","disabled":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-receipt ")])]}}],null,true)},[_c('span',[_vm._v("Quittung")])])]}},{key:"expanded-item",fn:function(ref){
            var parentItem = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headers.length}},[_c('div',{staticClass:"ma-4"},[_c('div',[_c('strong',[_vm._v("Behandlungen: ")]),_vm._v(" "+_vm._s(_vm.getAppointmentsSummary(parentItem.appointments_data))+" ")]),(parentItem.bemerkung)?_c('div',{staticClass:"pt-2"},[_c('strong',[_vm._v("Bemerkung: ")]),_vm._v(" "+_vm._s(parentItem.bemerkung)+" ")]):_vm._e()])])]}}])},[_c('template',{slot:"no-data"},[_c('div',[_vm._v("Keine aktuellen Rezepte gefunden")])])],2)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"primary-nav-btn",attrs:{"color":"primary","text":""},on:{"click":_vm.closeWindow}},[_vm._v("Zurück")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }